import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  noGap?: any;
  phoneMask?: boolean;
  maxLength?: number;
};

export const formatPhoneNumber = (value: string): string => {
  const digits = value.replace(/\D/g, '');
  const match = digits.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (!match) return value;

  const [, country, area, prefix, line] = match;

  let formatted = '+';
  if (country) formatted += country;
  if (area) formatted += ` (${area}`;
  if (area && (prefix || line)) formatted += `)`;
  if (prefix) formatted += ` ${prefix}`;
  if (line) formatted += `-${line}`;

  return formatted;
};

export default function RHFTextField({
  name,
  helperText,
  type,
  noGap = false,
  phoneMask = false,
  maxLength,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={
            // eslint-disable-next-line no-nested-ternary
            phoneMask
              ? formatPhoneNumber(field.value || '')
              : type === 'number' && field.value === 0
              ? ''
              : field.value
          }
          onChange={(event) => {
            const inputValue = event.target.value;

            if (phoneMask) {
              field.onChange(formatPhoneNumber(inputValue));
            } else if (type === 'number') {
              field.onChange(Number(inputValue));
            } else {
              field.onChange(
                noGap ? inputValue.replace(/\s+/g, '') : inputValue,
              );
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          inputProps={{ maxLength }}
          {...other}
        />
      )}
    />
  );
}
