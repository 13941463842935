import { useState } from 'react';
import { useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
// types
import { IUserItem } from 'src/types/user';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useDashboardContext } from 'src/components/dashboard';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { useAuthContext } from 'src/auth/hooks';
import MenuSimple from '../_examples/mui/menu-simple';

// ----------------------------------------------------------------------

type Props = {
  currentUser?: IUserItem;
};

const OPTIONS = [
  { value: 'marketing', label: 'Marketing' },
  { value: 'sales', label: 'Sales' },
];

export default function UserNewEditForm({ currentUser }: Props) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { inviteUser } = useDashboardContext();

  const defaultValues = {};

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const { createContact } = useAuthContext();

  const [errorMsg, setErrorMsg] = useState('');

  const onSubmit: any = handleSubmit(async (data: any) => {
    try {
      // await createContact?.(data);
      const newData = {
        ...data,
        username: data.email,
      };

      await inviteUser(newData);
      reset();
      enqueueSnackbar('Invite success!');
      router.push('/dashboard/users');
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="name" label="Name" />

              <RHFTextField name="fullName" label="Full name" />

              <RHFTextField name="email" label="Email" />

              <RHFSelect name="role" label="Role">
                {OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                onClick={onSubmit}
              >
                Invite Contact
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
