// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subDays,
  startOfToday,
} from 'date-fns';
// utils
import { fDate } from 'src/utils/format-time';
import Iconify from 'src/components/iconify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import CustomPopover from 'src/components/custom-popover';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
//
import { DateRangePickerProps } from './types';

// ----------------------------------------------------------------------

const shortcutsItems: any = [
  {
    label: 'This Week',
    getValue: () => {
      const today = startOfToday();
      return [startOfWeek(today), today];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const startOfLastWeek = startOfWeek(subDays(startOfToday(), 7));
      return [startOfLastWeek, endOfWeek(startOfLastWeek)];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = startOfToday();
      return [subDays(today, 7), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = startOfToday();
      return [startOfMonth(today), today];
    },
  },
  {
    label: 'Previous Month',
    getValue: () => {
      const startOfPreviousMonth = startOfMonth(
        subDays(startOfToday(), new Date().getDate()),
      );
      return [startOfPreviousMonth, endOfMonth(startOfPreviousMonth)];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

export default function CustomDateRangePicker({
  variant = 'input',
  //
  startDate,
  endDate,
  //
  onChangeStartDate,
  onChangeEndDate,
  //
  open,
  onClose,
  //
  onApply,
  //
  error,
}: DateRangePickerProps) {
  const mdUp = useResponsive('up', 'md');

  const isCalendarView = variant === 'calendar';
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);

  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      sx={{ bgcolor: 'background.paper', backgroundImage: 'none' }}
    >
      <DialogContent
        sx={{
          ...(isCalendarView &&
            mdUp && {
              overflow: 'unset',
            }),
        }}
      >
        <Stack
          justifyContent="center"
          spacing={isCalendarView ? 3 : 2}
          direction={isCalendarView && mdUp ? 'row' : 'column'}
          sx={{ pt: 1 }}
        >
          {isCalendarView ? (
            <StaticDateRangePicker
              sx={{
                '.MuiDateRangeCalendar-root > :first-child': {
                  display: 'none',
                },
                '.MuiChip-root': {
                  background: 'none',
                  color: 'text.primary',

                  '&:hover': {
                    background: 'none',
                  },
                },
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
                toolbar: { hidden: true },
              }}
              value={[startDate, endDate]}
              calendars={2}
              onChange={(e: any) => {
                onChangeStartDate(e?.[0]);
                onChangeEndDate(e?.[1]);
              }}
              maxDate={new Date()}
            />
          ) : (
            <>
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={onChangeStartDate}
              />

              <DatePicker
                label="End date"
                value={endDate}
                onChange={onChangeEndDate}
              />
            </>
          )}
        </Stack>

        {error && (
          <FormHelperText error sx={{ px: 2 }}>
            End date must be later than start date
          </FormHelperText>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between', pl: '200px' }}>
        <Stack
          sx={{
            color: 'text.primary',
            fontSize: '14px',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Stack flexDirection="row" alignItems="center">
            <IconButton onClick={() => onChangeStartDate(null)}>
              <Iconify
                icon="zondicons:close-solid"
                width={12}
                color="#637381"
              />
            </IconButton>
            {fDate(startDate || lastWeek, 'MMMM dd,yyyy')}{' '}
          </Stack>
          -
          <Stack flexDirection="row" alignItems="center">
            <IconButton onClick={() => onChangeEndDate(null)}>
              <Iconify
                icon="zondicons:close-solid"
                width={12}
                color="#637381"
              />
            </IconButton>
            {fDate(endDate || today, 'MMMM dd,yyyy')}
          </Stack>
        </Stack>
        <Stack gap={2} flexDirection="row">
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={error}
            variant="contained"
            onClick={() => {
              onApply();
              onClose();
            }}
          >
            Apply
          </Button>
        </Stack>
      </DialogActions>
    </CustomPopover>
  );
}
