import { useEffect, useState } from 'react';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import { useDashboardContext } from 'src/components/dashboard';
import { useCampaignsContext } from 'src/components/campaigns';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Hashicon } from '@emeraldpay/hashicon-react';
import { format } from 'date-fns';
// @mui
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Collapse,
  Paper,
  Typography,
  Table,
  TableHead,
  Stack,
  OutlinedInput,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { formatDate } from '../_examples/mui/table-view/utils';
import UserQuickEditForm from './user-quick-edit-form';
import RoleSelect from './role-select';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: any;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  isAdmin: boolean;
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  isAdmin,
}: Props) {
  const { updateLeadConfidenceAndLeadScore, editBudgetStatus } =
    useDashboardContext();
  const { changeRoleUser } = useCampaignsContext();

  const { id, leadBudget, budget, submissionId, leadConfidence, fullName } =
    row;

  const collapsible = useBoolean();
  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();

  const [isEditBudget, setEditBudget] = useState(false);
  const [valueBudget, setValueBudget] = useState(leadBudget);
  const [valueSlider, setValueSlider] = useState(leadConfidence);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    setValueSlider(leadConfidence);
  }, [leadConfidence]);

  const clickSaveBudget = async () => {
    if (Number(valueBudget) !== leadBudget)
      await editBudgetStatus(submissionId, { budget: valueBudget });
    setEditBudget(false);
  };

  const debouncedSlider = useDebounce(String(valueSlider));

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    updateLeadConfidenceAndLeadScore(row.id, { confidence: debouncedSlider });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSlider]);

  const [role, setRole] = useState(row?.role);

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
    const roleId: any = {
      Marketing: 5,
      Sales: 4,
    };
    changeRoleUser({
      userId: id,
      roleId: roleId?.[event.target.value as string],
    });
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {isAdmin && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}
        <TableCell>
          <Hashicon value={fullName + id} size={34} />
        </TableCell>

        <TableCell>
          <Box sx={{ whiteSpace: 'nowrap' }}>{fullName}</Box>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.email}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {(row?.role !== 'Marketing' && row?.role !== 'Sales') || !isAdmin ? (
            row?.role
          ) : (
            <>
              {/* <RoleSelect onChange={handleChange} value={role} /> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                onChange={handleChange}
                sx={{
                  minWidth: '95px',
                  '.MuiSelect-select': {
                    p: '2px 6px',
                    background: 'rgba(145, 158, 171, 0.16)',
                    fontWeight: 700,
                    lineHeight: '20px',
                    fontSize: '12px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >
                <MenuItem value="Marketing">Marketing</MenuItem>
                <MenuItem value="Sales">Sales</MenuItem>
              </Select>
            </>
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.createdAt
            ? format(new Date(row?.createdAt), 'dd MMM yyyy')
            : ''}
        </TableCell>

        {isAdmin && row?.role !== 'Authenticated' && (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={confirm.onTrue}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={12}>
          <Collapse in={collapsible.value} unmountOnExit>
            <Paper variant="outlined" sx={{ my: 3 }}>
              <Typography variant="h6" sx={{ m: 2, mt: 0 }}>
                Leads
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Budget 12</TableCell>
                    <TableCell>
                      <Stack
                        flexDirection="row"
                        gap={1}
                        alignItems="center"
                        sx={{ width: '150px' }}
                      >
                        {isEditBudget ? (
                          <>
                            <OutlinedInput
                              sx={{
                                '.MuiOutlinedInput-input': {
                                  p: '5px',
                                  maxWidth: '70px',
                                },
                              }}
                              value={valueBudget}
                              onChange={(e) => setValueBudget(e.target.value)}
                            />
                            <IconButton onClick={clickSaveBudget}>
                              <Iconify icon="bi:save" />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Box>{budget}</Box>
                            <IconButton
                              onClick={() => {
                                setEditBudget(true);
                              }}
                            >
                              <Iconify icon="solar:pen-bold" />
                            </IconButton>
                          </>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell align="right">Created date</TableCell>
                    <TableCell align="right">Last updated</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>

      <UserQuickEditForm
        currentUser={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            quickEdit.onTrue();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack flexDirection="row" spacing={2} alignItems="center">
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete User?
            </Stack>
            <IconButton onClick={confirm.onFalse}>
              <Iconify icon="iconoir:cancel" />
            </IconButton>
          </Stack>
        }
        content={
          <>
            After the user is removed from your list, they no longer have access
            to your space.
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
