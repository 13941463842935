import { lazy, Suspense } from 'react';
// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// theme
import ThemeProvider from 'src/theme';
// hooks
// import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { SplashScreen } from 'src/components/loading-screen';
// layouts
import MainLayout from 'src/layouts/main';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';

import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

import { mainRoutes } from 'src/routes/sections/main';
import { authRoutes } from 'src/routes/sections/auth';
import { authDemoRoutes } from 'src/routes/sections/auth-demo';
import { dashboardRoutes } from 'src/routes/sections/dashboard';
import { componentsRoutes } from 'src/routes/sections/components';
import CompactLayout from 'src/layouts/compact';
import { FormsProvider } from './components/forms';
import AuthModernLayout from './layouts/auth/modern';
import { GuestGuard } from './auth/guard';
import { CampaignsProvider } from './components/campaigns';
import { DashboardProvider } from './components/dashboard';
import { onboardingRoutes } from './routes/sections/onboarding';

const RegisterPage = lazy(() => import('src/pages/auth-main/register'));
const LoginPage = lazy(() => import('src/pages/auth-main/login'));
const ForgotPasswordPage = lazy(
  () => import('src/pages/auth-main/forgot-password'),
);
const NewPasswordPage = lazy(() => import('src/pages/auth-main/new-password'));
const VerifyPage = lazy(() => import('src/pages/auth-main/verify'));

// ----------------------------------------------------------------------

setupIonicReact();

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <GuestGuard>
        <MainLayout>
          <AuthModernLayout>
            <LoginPage />
          </AuthModernLayout>
        </MainLayout>
      </GuestGuard>
    ),
  },
  {
    path: 'register',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <AuthModernLayout>
          <RegisterPage />
        </AuthModernLayout>
      </Suspense>
    ),
  },
  {
    path: 'join-link',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <AuthModernLayout>
          <RegisterPage />
        </AuthModernLayout>
      </Suspense>
    ),
  },
  {
    path: 'join',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <AuthModernLayout>
          <RegisterPage />
        </AuthModernLayout>
      </Suspense>
    ),
  },
  {
    path: 'forgot-password',
    element: (
      <CompactLayout>
        <ForgotPasswordPage />
      </CompactLayout>
    ),
  },
  {
    path: 'reset-password',
    element: (
      <CompactLayout>
        <NewPasswordPage />
      </CompactLayout>
    ),
  },
  {
    path: 'verify',
    element: (
      <CompactLayout>
        <VerifyPage />
      </CompactLayout>
    ),
  },
  ...authRoutes,
  ...authDemoRoutes,
  ...dashboardRoutes,
  ...mainRoutes,
  ...componentsRoutes,
  ...onboardingRoutes,
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
]);

export default function App() {
  const charAt = `

  ░░                      ░░      ░░
  ▒▒                      ▒▒      ▒▒  
  ▒▒▒▒   ▒▒ ▒▒     ▒▒     ▒▒ ▒▒   ▒▒▒▒
  ▓▓     ▓▓      ▓▓▓▓▓▓   ▓▓▓     ▓▓ 
  ████   ██      ██  ██   ██ ██   ████ 

  `;

  console.info(`%c${charAt}`, 'color: green');

  // useScrollToTop();

  return (
    <AuthProvider>
      <DashboardProvider>
        <CampaignsProvider>
          <FormsProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider
                defaultSettings={{
                  themeMode: 'dark', // 'light' | 'dark'
                  themeDirection: 'ltr', //  'rtl' | 'ltr'
                  themeContrast: 'default', // 'default' | 'bold'
                  themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                  themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                  themeStretch: false,
                }}
              >
                <ThemeProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <SettingsDrawer />
                      <ProgressBar />
                      <AuthConsumer>
                        <RouterProvider router={router} />
                      </AuthConsumer>
                    </SnackbarProvider>
                  </MotionLazy>
                </ThemeProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </FormsProvider>
        </CampaignsProvider>
      </DashboardProvider>
    </AuthProvider>
  );
}
