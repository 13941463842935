import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

export const ChangeMode = () => {
  const settings = useSettingsContext();

  return (
    <Box>
      {settings.themeMode === 'dark' ? (
        <IconButton onClick={() => settings.onUpdate('themeMode', 'light')}>
          <SvgColor src="/assets/icons/setting/ic_sun.svg" />
        </IconButton>
      ) : (
        <IconButton onClick={() => settings.onUpdate('themeMode', 'dark')}>
          <SvgColor src="/assets/icons/setting/ic_moon.svg" />
        </IconButton>
      )}
    </Box>
  );
};
