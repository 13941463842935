import useSWR from 'swr';
import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
// utils
import { fetcher, endpoints } from 'src/utils/axios';
// types
import { IPostItem } from 'src/types/blog';

// ----------------------------------------------------------------------

export function useGetUsersList() {
  const URL = endpoints.users.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      users: (data as any) || [],
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetUsersListRequests({
  accountId,
  page = 1,
  limit = 50,
}: any) {
  const URL = `${endpoints.users.requests}`;

  const config: AxiosRequestConfig = useMemo(
    () => ({
      params: {
        cid: 'zxcvb',
        accountId,
        page,
        limit,
      },
    }),
    [accountId, page, limit],
  );

  const { data, isLoading, error, isValidating, mutate } = useSWR(
    [URL, config],
    fetcher,
  );

  const memoizedValue = useMemo(
    () => ({
      users: (data?.data as any) || [],
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
      reloadUsersRequests: mutate,
    }),
    [data?.data, error, isLoading, isValidating, mutate],
  );

  return memoizedValue;
}

export function useGetUserRolesList() {
  const URL = endpoints.users.roles;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      roles: (data as any) || [],
      rolesLoading: isLoading,
      rolesError: error,
      rolesValidating: isValidating,
    }),
    [data, error, isLoading, isValidating],
  );

  return memoizedValue;
}
