import { useCallback, useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// routes
import { useRouter } from 'src/routes/hooks';
// components
import { useDashboardContext } from 'src/components/dashboard';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks';
//
import Main from './main';
import Header from './header';
// import { HeaderStart as Header } from '../_common';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function OnboardingLayout({ children }: Props) {
  const router = useRouter();
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const { attributes } = useDashboardContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (user && !user.account_id && !attributes && !user?.account?.length) {
      router.replace('/onboarding');
    } else {
      setChecked(true);
    }
  }, [user, router, attributes]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollToTop();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = (
    <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  if (isHorizontal) {
    return (
      <>
        {/* <Header onOpenNav={nav.onTrue} /> */}
        <Header />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        {/* <Header onOpenNav={nav.onTrue} /> */}
        <Header />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Main>{children}</Main>
      </Box>
    </>
  );
}
