import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FormHelperText } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { useState, SyntheticEvent } from 'react';
import { useDashboardContext } from 'src/components/dashboard';
import { useAuthContext } from 'src/auth/hooks';
import { useGetUsersListRequests } from 'src/api/users';
import RoleSelect from './role-select';

export const UserInviteDialog = ({ invite, countPeople = 0 }: any) => {
  const { requestUser, inviteLink } = useDashboardContext();
  const { user: userInfo } = useAuthContext();

  const { reloadUsersRequests } = useGetUsersListRequests({});

  const { enqueueSnackbar } = useSnackbar();

  const [isCopy, setCopy] = useState(false);

  const [emails, setEmails] = useState<string[]>([]);
  const [role, setRole] = useState('5');
  const [error, setError] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleOnChange = (
    _event: SyntheticEvent<Element, Event>,
    value: (string | never[])[],
  ) => {
    setError(false);
    const validEmails = value.filter(
      (email) => typeof email === 'string' && emailRegex.test(email),
    ) as string[];
    setEmails(validEmails);
  };

  const clickCopy = async () => {
    try {
      const link = await inviteLink({
        roleId: 5,
        accountId: userInfo?.account_id.id,
      });

      await navigator.clipboard.writeText(link);

      setCopy(true);
    } catch (e) {
      console.log(e);
    }
  };

  const clickInvite = async () => {
    try {
      await Promise.all(
        emails.map((email) =>
          requestUser({
            email,
            roleId: Number(role),
            accountId: userInfo?.account_id.id,
          }),
        ),
      );
      await reloadUsersRequests();
      // getUsersRequestsList({ accountId: userInfo?.account_id.id });
      enqueueSnackbar('Invite success!');
      setEmails([]);
    } catch (e) {
      console.log(e);
      // enqueueSnackbar(e?.response?.data?.error?.details || 'Invite failed!', {
      //   variant: 'error',
      // });
      setError(true);
    }
  };

  return (
    <ConfirmDialog
      open={invite.value}
      onClose={invite.onFalse}
      title={
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize={18} fontWeight={700}>
            Invite people to your space
          </Typography>
          <Stack flexDirection="row" spacing={1} alignItems="center">
            <Box sx={{ color: '#00A76F', fontSize: '13px', fontWeight: 700 }}>
              {!isCopy ? (
                <Button sx={{ gap: '10px' }} onClick={clickCopy}>
                  <Iconify icon="material-symbols:link" />
                  Create Invite Link
                </Button>
              ) : (
                <Box>Link Copied!</Box>
              )}
            </Box>
            <IconButton onClick={invite.onFalse}>
              <Iconify icon="iconoir:cancel" />
            </IconButton>
          </Stack>
        </Stack>
      }
      content={
        <Box sx={{ p: '8px 0 24px' }}>
          <Stack flexDirection="row" alignItems="center" spacing={2}>
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                flex: 1,
                borderRadius: '8px',
                border: '1px solid rgba(145, 158, 171, 0.20)',
                p: '2px 4px',
              }}
            >
              <Autocomplete
                sx={{
                  flex: 1,

                  '.MuiInputBase-root': {
                    p: 0,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '.MuiPoper-root': {
                    background: 'red',
                    // display: 'none!important',
                  },
                }}
                multiple
                clearIcon={null}
                id="tags-filled"
                options={[]}
                defaultValue={[]}
                freeSolo
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        key={key}
                        {...tagProps}
                        sx={{
                          background: 'rgba(145, 158, 171, 0.12)',
                          border: 'none',
                          height: '28px',
                        }}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Email" />
                )}
                value={emails}
                onChange={handleOnChange}
              />
              {Boolean(emails.length) && (
                <RoleSelect value={role} onChange={handleChange} />
              )}
            </Stack>
            <Button
              onClick={clickInvite}
              sx={{
                background: '#212B36',
                p: '6px 12px',
                fontSize: '14px',
                fontWeight: 700,
                color: '#fff',

                '&:hover': {
                  background: '#212B36',
                },

                '&:disabled': {
                  background: 'rgba(145, 158, 171, 0.24)',
                },
              }}
              disabled={!emails.length}
            >
              Invite
            </Button>
          </Stack>
          {error && (
            <FormHelperText
              error
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pl: 1.5 }}
            >
              <Iconify icon="material-symbols:info" /> Looks like this user is
              already a member of your team
            </FormHelperText>
          )}
          <Box sx={{ fontWeight: 600, mt: 3 }}>
            Your space has <b>{countPeople} people</b>. You can view and manage
            your team members on the user management page.
          </Box>
        </Box>
      }
      noCancel
      sx={{
        '.MuiPaper-root': {
          minWidth: '550px',
        },
        '.MuiDialogActions-root': {
          justifyContent: 'space-between',
        },
      }}
    />
  );
};
